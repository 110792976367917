<template>
  <div class="ps-cart--mini">
    <router-link :to="{ name: 'cart' }" class="header__extra cart-button">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="35"
        height="35"
        viewBox="0 0 172 172"
        style=" fill:#000000;"
      >
        <g transform="translate(6.45,6.45) scale(0.925,0.925)">
          <g
            fill="none"
            fill-rule="nonzero"
            stroke="none"
            stroke-width="none"
            stroke-linecap="butt"
            stroke-linejoin="none"
            stroke-miterlimit="10"
            stroke-dasharray=""
            stroke-dashoffset="0"
            font-family="none"
            font-weight="none"
            font-size="none"
            text-anchor="none"
            style="mix-blend-mode: normal"
          >
            <g
              fill="#fda31e"
              stroke="#666666"
              stroke-width="14"
              stroke-linejoin="round"
            >
              <path
                d="M22.23906,11.46667c6.33016,0 11.95167,4.21305 13.73984,10.29089c0.00373,0 0.00747,0 0.0112,0l7.08828,24.10911h111.72161c1.78765,-0.00018 3.4731,0.8334 4.55795,2.25424c1.08485,1.42084 1.44495,3.26636 0.97382,4.99081l-14.89323,54.61224c-2.03838,7.45122 -8.8644,12.67604 -16.59532,12.67604h-62.93229c-7.60633,0 -14.35007,-5.04895 -16.49453,-12.34011l-24.43385,-83.06614c-0.36757,-1.24936 -1.44832,-2.06042 -2.74349,-2.06042h-10.7724c-2.06765,0.02924 -3.99087,-1.05709 -5.03322,-2.843c-1.04236,-1.78592 -1.04236,-3.99474 0,-5.78066c1.04236,-1.78592 2.96558,-2.87225 5.03322,-2.843zM80.26667,143.33333c0,6.33287 -5.1338,11.46667 -11.46667,11.46667c-6.33287,0 -11.46667,-5.1338 -11.46667,-11.46667c0,-6.33287 5.1338,-11.46667 11.46667,-11.46667c6.33287,0 11.46667,5.1338 11.46667,11.46667zM137.6,143.33333c0,6.33287 -5.1338,11.46667 -11.46667,11.46667c-6.33287,0 -11.46667,-5.1338 -11.46667,-11.46667c0,-6.33287 5.1338,-11.46667 11.46667,-11.46667c6.33287,0 11.46667,5.1338 11.46667,11.46667z"
              ></path>
            </g>
            <path
              d="M0,172v-172h172v172z"
              fill="none"
              stroke="none"
              stroke-width="1"
              stroke-linejoin="miter"
            ></path>
            <g
              fill="#fda31e"
              stroke="none"
              stroke-width="1"
              stroke-linejoin="miter"
            >
              <path
                d="M11.46667,11.46667c-2.06765,-0.02924 -3.99087,1.05709 -5.03322,2.843c-1.04236,1.78592 -1.04236,3.99474 0,5.78066c1.04236,1.78592 2.96558,2.87225 5.03322,2.843h10.7724c1.29517,0 2.37592,0.81106 2.74349,2.06042l24.43385,83.06614c2.14446,7.29116 8.88821,12.34011 16.49453,12.34011h62.93229c7.73092,0 14.55694,-5.22483 16.59532,-12.67604l14.89323,-54.61224c0.47113,-1.72445 0.11102,-3.56997 -0.97382,-4.99081c-1.08485,-1.42084 -2.7703,-2.25442 -4.55795,-2.25424h-111.72161l-7.08828,-24.10911c-0.00373,0 -0.00747,0 -0.0112,0c-1.78818,-6.07784 -7.40968,-10.29089 -13.73984,-10.29089zM68.8,131.86667c-6.33287,0 -11.46667,5.1338 -11.46667,11.46667c0,6.33287 5.1338,11.46667 11.46667,11.46667c6.33287,0 11.46667,-5.1338 11.46667,-11.46667c0,-6.33287 -5.1338,-11.46667 -11.46667,-11.46667zM126.13333,131.86667c-6.33287,0 -11.46667,5.1338 -11.46667,11.46667c0,6.33287 5.1338,11.46667 11.46667,11.46667c6.33287,0 11.46667,-5.1338 11.46667,-11.46667c0,-6.33287 -5.1338,-11.46667 -11.46667,-11.46667z"
              ></path>
            </g>
            <path
              d=""
              fill="none"
              stroke="none"
              stroke-width="1"
              stroke-linejoin="miter"
            ></path>
            <path
              d=""
              fill="none"
              stroke="none"
              stroke-width="1"
              stroke-linejoin="miter"
            ></path>
          </g>
        </g>
      </svg>
      <span>
        <i>{{ cart.cartCount }}</i>
      </span>
    </router-link>
    <!--    <div class="ps-cart__content" id="mini-cart" style="visibility: visible" v-if="cart.cartCount > 0">-->
    <!--      <div class="ps-cart__items">-->
    <!--        <div v-for="cartItem in cart.cartItems" :key="cartItem.id" class="ps-product&#45;&#45;cart-mobile">-->
    <!--          <div class="ps-product__thumbnail">-->
    <!--            <a href="#"><img src="../assets/product.png" alt="" /></a>-->
    <!--          </div>-->
    <!--          <div class="ps-product__content">-->
    <!--            <a class="ps-product__remove" href="#"><i class="icon-cross"></i></a>-->
    <!--            {{ cartItem.title }}-->
    <!--            <p><strong>Sold by:</strong> {{ cartItem.shop.name }}</p>-->
    <!--            <small>{{ cartItem.quantity }} x ${{ cartItem.price }}</small>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="ps-cart__footer">-->
    <!--        <h3>-->
    <!--          Sub Total:<strong>{{ cart.total | toCurrency }}</strong>-->
    <!--        </h3>-->
    <!--        <figure>-->
    <!--          <router-link :to="{name: 'cart'}" class="ps-btn">View Cart</router-link>-->
    <!--          <router-link :to="{name: 'checkout'}" class="ps-btn">Checkout</router-link>-->
    <!--        </figure>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
import global from "@/mixins/global";
import { mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
    };
  },
  mixins: [global],
  computed: {
    ...mapState({
      cart: (state) => state.cart,
    }),
  },
};
</script>
